<template>
    <div>
        <client-nav></client-nav>
        <section class="section">
            <router-view></router-view>
        </section>
    </div>
</template>
<script>
import ClientNav from '@/components/ClientNav'

export default {
    components: {
        "client-nav": ClientNav
    },

    created() {
        this.$store.dispatch("clientOrders/getOrders")
    }
}
</script>